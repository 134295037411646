import React from "react"
import Layout from "../layouts/default"
import { graphql } from "gatsby"

const pageData = {
  title: "Ou nou!",
  description: "Etsimäsi sivu on kadonnut bittiavaruuteen.",
}

const ErrorPage = ({ data }) => {
  return (
    <Layout pageData={pageData}>
      <div className="row">
        <div className="col">moikka!</div>
      </div>
    </Layout>
  )
}

export default ErrorPage

export const query = graphql`
  query {
    allContentfulCategory(sort: { fields: order }) {
      edges {
        node {
          shortTitle
          slug
          categoryVideos {
            slug
            title
            poster {
              fluid(maxWidth: 400, maxHeight: 225) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
